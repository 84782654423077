import {
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  forwardRef,
  useRef,
  useState,
} from "react";

import { isEmpty, pickBy } from "lodash";
import { useQuery } from "@tanstack/react-query";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { motion, AnimatePresence } from "framer-motion";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import styles from "./SearchBar.module.css";
import APICompanyMatchProfile from "../../types/APICompanyMatchProfile";
import { useTrueBizApi } from "../../api";
import { useShowNetworkFailureToast } from "../NetworkFailureToast";

interface Props {
  onSearch?: (searchValues: {
    domain?: string;
    businessName?: string;
    description?: string;
    email?: string;
    phone?: string;
    applicantFullName?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
    country?: string;
    matchProfileId?: UUID;
    externalTrackingRef?: string;
  }) => Promise<void>;
  domain?: string;
  advanced?: {
    businessName?: string;
    description?: string;
    email?: string;
    phone?: string;
    applicantFullName?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
    country?: string;
    externalTrackingRef?: string;
  };
  matchProfileId?: UUID | null | undefined;
  matchProfiles?: APICompanyMatchProfile[];
  children?: ReactNode;
  style?: CSSProperties;
  autoFocus?: boolean;
  fullWidth?: boolean;
  childrenPosition?: "top" | "right";
  errorReason?: string;
  onChange?: (value: string) => void;
}

const SearchBar = forwardRef<() => Promise<void>, Props>(function SearchBar(
  {
    onSearch,
    domain: initialDomain,
    advanced: initialAdvanced,
    matchProfileId: initialMatchProfileId,
    matchProfiles: initialMatchProfiles,
    children,
    childrenPosition = "top",
    style,
    autoFocus = true,
    fullWidth = false,
    errorReason,
    onChange,
  }: Props,
  ref
) {
  const navigate = useNavigate();
  const api = useTrueBizApi();
  const domainInputRef = useRef<HTMLInputElement | null>(null);
  const domainWrapperRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();
  const showToast = useShowNetworkFailureToast();

  const [domain, setDomain] = useState(initialDomain);

  const matchProfileQuery = useQuery({
    queryKey: ["getCompanyMatchProfiles"],
    queryFn: () => api.getCompanyMatchProfiles(),
    enabled: initialMatchProfiles === undefined,
  });

  const matchProfiles = initialMatchProfiles || matchProfileQuery.data;
  const [matchProfileId, setMatchProfileId] = useState<UUID | "">(
    initialMatchProfileId || ""
  );

  // if an account only has one match profile, use it as the default,
  // but only if we haven't already specified a profile to use
  useEffect(() => {
    if (!matchProfiles) return;
    if (initialMatchProfileId !== undefined) return;

    if (matchProfiles.length === 0) {
      setMatchProfileId("");
      return;
    }

    setMatchProfileId((prev) => (prev === "" ? matchProfiles[0].id : prev));
  }, [matchProfiles, initialMatchProfileId]);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(
    !isEmpty(initialAdvanced)
  );
  const advancedSearchForm = useFormik({
    initialValues: {
      businessName: initialAdvanced?.businessName || "",
      description: initialAdvanced?.description || "",
      email: initialAdvanced?.email || "",
      phone: initialAdvanced?.phone || "",
      applicantFullName: initialAdvanced?.applicantFullName || "",
      addressLine1: initialAdvanced?.addressLine1 || "",
      addressLine2: initialAdvanced?.addressLine2 || "",
      city: initialAdvanced?.city || "",
      stateProvince: initialAdvanced?.stateProvince || "",
      postalCode: initialAdvanced?.postalCode || "",
      country: initialAdvanced?.country || "",
      externalTrackingRef: initialAdvanced?.externalTrackingRef || "",
    },
    onSubmit: () => {
      search();
    },
  });

  const playErrorAnimation = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (!domainWrapperRef.current) return resolve();
        const el = domainWrapperRef.current;

        const listen = (e: AnimationEvent) => {
          if (e.animationName === styles.shake) {
            el.removeEventListener("animationend", listen);
            resolve();
          }
        };

        el.addEventListener("animationend", listen);

        if (el.classList.contains(styles.invalid)) {
          domainWrapperRef.current.classList.remove(styles.invalid);
        }

        // ensure that the animation (re-)plays
        requestAnimationFrame(() => {
          el.classList.add(styles.invalid);
        });
      }),
    []
  );

  const advancedSearchFormValues = advancedSearchForm.values; // this is stupid but it keeps eslint happy

  const search = useCallback(async () => {
    if (!showAdvancedSearch) {
      if (!domain) {
        await playErrorAnimation();
        domainInputRef.current?.focus();
        return;
      }
    }

    if (
      !domain &&
      ![
        advancedSearchFormValues.businessName,
        advancedSearchFormValues.addressLine1,
        advancedSearchFormValues.city,
      ].every((x) => !!x)
    ) {
      showToast(
        "If no domain is provided, both business name and address are required."
      );
      return;
    }

    if (onSearch) {
      await onSearch({ domain, ...advancedSearchFormValues, matchProfileId });
    } else {
      navigate(
        `/?${queryString.stringify(
          pickBy({
            domain,
            ...advancedSearchFormValues,
            matchProfileId,
            run: 1,
          })
        )}`
      );
    }
  }, [
    playErrorAnimation,
    domain,
    showAdvancedSearch,
    matchProfileId,
    onSearch,
    navigate,
    showToast,
    advancedSearchFormValues,
  ]);

  useEffect(() => {
    if (!ref) return;
    if (typeof ref === "function") {
      ref(search);
    } else {
      ref.current = search;
    }

    return () => {
      if (!ref) return;
      if (typeof ref === "function") {
        ref(null);
      } else {
        ref.current = null;
      }
    };
  }, [search, ref]);

  useEffect(() => {
    if (!showAdvancedSearch) {
      advancedSearchForm.resetForm();
    }
  }, [showAdvancedSearch, advancedSearchForm.resetForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!autoFocus) return;
    if (!showAdvancedSearch) {
      domainInputRef.current?.focus();
    }
  }, [autoFocus, showAdvancedSearch]);

  return (
    <form
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(style || {}),
      }}
      onSubmit={advancedSearchForm.handleSubmit}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: fullWidth ? undefined : "550px",
          }}
        >
          {children && childrenPosition === "top" ? children : null}
          <AnimatePresence>
            {errorReason && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, height: 0 }}
                style={{ overflow: "hidden" }}
              >
                <Typography
                  variant="body2"
                  mb={1}
                  color="error"
                  textAlign="center"
                >
                  {errorReason}
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>
          <TextField
            inputRef={domainInputRef}
            ref={domainWrapperRef}
            value={domain}
            label={showAdvancedSearch ? "Domain" : undefined}
            placeholder="www."
            fullWidth
            autoFocus={autoFocus}
            title="Search for a domain"
            sx={{
              "&:hover:not(.Mui-focused)": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            inputProps={{
              tabIndex: 1,
            }}
            InputProps={{
              endAdornment: !showAdvancedSearch ? (
                <InputAdornment position="end">
                  <IconButton tabIndex={4} onClick={() => search()}>
                    <SearchOutlinedIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            onChange={(e) => {
              setDomain(e.target.value);
              onChange?.(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                search();
              }
            }}
          />
          <div
            style={{
              width: "100%",
              paddingTop: "0.5em",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* Risk profile dropdown */}
            <AnimatePresence
              initial={
                !initialMatchProfiles?.length && matchProfileQuery.isLoading
              }
            >
              {matchProfiles && matchProfiles.length > 1 && (
                <motion.div
                  style={{
                    display: "flex",
                    paddingLeft: "3px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <Select
                    title="Risk Profile"
                    value={matchProfileId}
                    onChange={(e) => {
                      setMatchProfileId(e.target.value);
                    }}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderStyle: "none",
                      },
                      "& .MuiSelect-select": {
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                      },
                      "& .MuiOutlinedInput-input:focus": {
                        backgroundColor: "rgba(0,0,0,0.05)",
                      },
                      color: theme.palette.primary.main,
                    }}
                    inputProps={{
                      tabIndex: 2,
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" key="default">
                      TrueBiz Default
                    </MenuItem>
                    {matchProfiles.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.name.replace(/\([0-9a-fA-f-]+\)/, "")}
                      </MenuItem>
                    ))}
                  </Select>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Advanced search button */}
            <div
              style={{
                paddingRight: "3px",
                marginLeft: "auto",
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: !showAdvancedSearch
                    ? "#C4C4C4"
                    : theme.palette.primary.main,
                  textTransform: "unset",
                  fontSize: "unset",
                  padding: 0,
                  // fontWeight: 600, // "semi-bold"

                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent",
                  },
                }}
                tabIndex={3}
                onClick={() => {
                  setShowAdvancedSearch((x) => !x);
                }}
              >
                {!showAdvancedSearch
                  ? "Advanced Search"
                  : "Return to Web Presence Review"}
              </Button>
            </div>
          </div>
        </div>
        {children && childrenPosition === "right" ? children : null}
      </div>
      <AnimatePresence>
        {showAdvancedSearch && (
          <motion.div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
            initial={{ height: 0, opacity: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            key="advanced"
          >
            <div
              style={{
                display: "flex",
                gap: "1.25em",
                marginTop: "2em",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                }}
              >
                <TextField
                  fullWidth
                  name="businessName"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.businessName}
                  label="Business Name"
                  type="text"
                  inputProps={{ tabIndex: 5 }}
                />
                <TextField
                  fullWidth
                  name="email"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.email}
                  label="Email"
                  type="text"
                  inputProps={{ tabIndex: 6 }}
                />
                <TextField
                  fullWidth
                  name="phone"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.phone}
                  label="Phone"
                  type="text"
                  inputProps={{ tabIndex: 7 }}
                />
                <TextField
                  fullWidth
                  name="applicantFullName"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.applicantFullName}
                  label="Applicant Full Name"
                  type="text"
                  inputProps={{ tabIndex: 8 }}
                />
              </div>
              <hr
                style={{
                  margin: 0,
                  borderStyle: "solid",
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderColor: theme.palette.primary.main,
                }}
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                }}
              >
                <TextField
                  fullWidth
                  name="addressLine1"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.addressLine1}
                  label="Address Line 1"
                  type="text"
                  inputProps={{ tabIndex: 9 }}
                />
                <TextField
                  fullWidth
                  name="addressLine2"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.addressLine2}
                  label="Address Line 2"
                  type="text"
                  inputProps={{ tabIndex: 10 }}
                />
                <div style={{ display: "flex", gap: "1em" }}>
                  <TextField
                    fullWidth
                    name="city"
                    onChange={advancedSearchForm.handleChange}
                    value={advancedSearchForm.values.city}
                    label="City"
                    type="text"
                    inputProps={{ tabIndex: 11 }}
                  />
                  <TextField
                    fullWidth
                    name="stateProvince"
                    onChange={advancedSearchForm.handleChange}
                    value={advancedSearchForm.values.stateProvince}
                    label="State/Province"
                    type="text"
                    inputProps={{ tabIndex: 12 }}
                  />
                </div>
                <div style={{ display: "flex", gap: "1em" }}>
                  <TextField
                    fullWidth
                    name="postalCode"
                    onChange={advancedSearchForm.handleChange}
                    value={advancedSearchForm.values.postalCode}
                    label="Postal Code"
                    type="text"
                    inputProps={{ tabIndex: 13 }}
                  />
                  <TextField
                    fullWidth
                    name="country"
                    onChange={advancedSearchForm.handleChange}
                    value={advancedSearchForm.values.country}
                    label="Country"
                    type="text"
                    inputProps={{ tabIndex: 14 }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <TextField
                  sx={{
                    mt: 2,
                    ".MuiInputBase-inputMultiline": {
                      resize: "vertical",
                    },
                  }}
                  multiline
                  minRows={3}
                  fullWidth
                  name="description"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.description}
                  label="Business Description"
                  type="text"
                  inputProps={{ tabIndex: 15 }}
                />
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <div>
                <TextField
                  fullWidth
                  name="externalTrackingRef"
                  onChange={advancedSearchForm.handleChange}
                  value={advancedSearchForm.values.externalTrackingRef}
                  label="Reference ID"
                  type="text"
                  inputProps={{ tabIndex: 15 }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "2em",
              }}
            >
              <Button
                startIcon={<SearchOutlinedIcon />}
                variant="contained"
                sx={{
                  textTransform: "unset",
                  fontSize: "unset",
                }}
                tabIndex={16}
                type="submit"
              >
                Search
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
});

export default SearchBar;

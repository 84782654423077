import { useContext, useState, createContext, ReactNode } from "react";

interface TableContextState {
  disabled: boolean;
  working: boolean;
  showSkeleton: boolean;
}

type SetTableContextState = (
  nextState:
    | TableContextState
    | ((prevState: TableContextState) => TableContextState)
) => void;

const TableCtx = createContext<
  [TableContextState, SetTableContextState] | null
>(null);

export default function GlobalTableStateProvider({
  children,
  disabled = false,
  working = false,
  showSkeleton = false,
}: { children: ReactNode } & Partial<TableContextState>) {
  const [state, setState] = useState<TableContextState>({
    disabled,
    working,
    showSkeleton,
  });

  return (
    <TableCtx.Provider value={[state, setState]}>{children}</TableCtx.Provider>
  );
}

export const useGlobalTableState = () => {
  const ctx = useContext(TableCtx);
  if (!ctx)
    throw new Error(
      "useGlobalTableState must be used within a GlobalTableStateProvider"
    );

  return ctx;
};

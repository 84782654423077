import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import { csvFormat } from "d3-dsv";

import { orderBy, uniq } from "lodash";
import { ExportRow } from "./types";
import { useGlobalTableState } from "./GlobalTableState";

export const getIssuesForCSVExport = (issues: ExportRow[]) =>
  issues.map((issue) => ({
    id: issue.id,
    created_at: issue.date,
    domain: issue.domain,
    status: issue.status,
    messages: orderBy(uniq(issue.messages)).join(", "),
    assigned_to: !issue.assignee ? "(unassigned)" : issue.assignee.email,
    acknowledged_at: issue.acknowledged_at,
    acknowledged_by: issue.acknowledged_by,
    acknowledged_reason: issue.acknowledged_reason,
    acknowledged_notes: issue.acknowledged_notes,
  }));

export interface Props {
  domains: string[];
  disabled?: boolean;
  onSuccess?: () => void;
  onError?: (reason?: Error) => void;
  getIssuesByDomains: (domains: string[]) => Promise<{ issues: ExportRow[] }>;
}

export default function ExportButton({
  domains,
  getIssuesByDomains,
  disabled,
  onSuccess,
  onError,
}: Props) {
  const [working, setWorking] = useState(false);
  const [_, setGlobalTableState] = useGlobalTableState(); // eslint-disable-line @typescript-eslint/no-unused-vars

  return (
    <LoadingButton
      variant="outlined"
      size="small"
      loading={working}
      startIcon={<DownloadIcon />}
      sx={{ pl: "2em", pr: "2em", display: "flex", flexShrink: 0 }}
      disabled={disabled || working}
      onClick={async () => {
        try {
          setWorking(true);
          setGlobalTableState((s) => ({ ...s, working: true, disabled: true }));

          const issues = (await getIssuesByDomains(domains)).issues;
          const csvData = csvFormat(getIssuesForCSVExport(issues));

          const filename = `truebiz-export-${new Date()
            .toISOString()
            .replace(/:/g, "-")
            .replace(/\./g, "-")}.csv`;
          const url = URL.createObjectURL(
            new Blob([csvData], { type: "text/csv" })
          );
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = filename;
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
          URL.revokeObjectURL(url);

          setWorking(false);
          onSuccess?.();
        } catch (e) {
          const error =
            e instanceof Error ? e : e === undefined ? e : new Error(String(e));
          onError?.(error);
        } finally {
          setWorking(false);
          setGlobalTableState((s) => ({
            ...s,
            working: false,
            disabled: false,
          }));
        }
      }}
    >
      Export
    </LoadingButton>
  );
}

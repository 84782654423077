import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import APIRiskIndicators from "../../../types/APIRiskIndicators";
import { useTrueBizApi } from "../../../api";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";

interface FeedbackFormProps {
  pass: boolean;
  trackingId: UUID;
  risks: APIRiskIndicators;
  onCancel: () => void;
  onSuccess: () => void;
}

function FeedbackForm({
  pass,
  trackingId,
  risks,
  onCancel,
  onSuccess,
}: FeedbackFormProps) {
  const api = useTrueBizApi();
  const theme = useTheme();
  const showToast = useShowNetworkFailureToast();

  const [working, setWorking] = useState(false);

  const [topLevelComments, setTopLevelComments] = useState("");
  const [topLevelDisagree, setTopLevelDisagree] = useState(false);
  const [selectedRisks, setSelectedRisks] = useState<string[]>([]);

  const onSubmit = useCallback(async () => {
    try {
      setWorking(true);

      await api.submitCompanyLookupDecisionFeedback(trackingId, {
        disagree: topLevelDisagree,
        comments: topLevelComments || null,
        risk_feedback: selectedRisks.map((risk_name) => ({
          risk_name,
          disagree: true,
          comments: null,
        })),
      });

      onSuccess();
    } catch (e) {
      showToast();
    } finally {
      setWorking(false);
    }
  }, [
    api,
    showToast,
    topLevelComments,
    topLevelDisagree,
    selectedRisks,
    trackingId,
    onSuccess,
  ]);

  const findings = useMemo(
    () => [...(risks?.risk || []), ...(risks?.notice || [])],
    [risks]
  );

  return (
    <Box paddingBottom={3}>
      <Paper
        sx={{
          background: theme.palette.grey[50],
        }}
        elevation={4}
      >
        <Stack padding={3}>
          <Box display="flex" alignItems="center">
            <Box mr={3}>
              <Typography color={theme.palette.grey[800]} fontSize="115%">
                Do you agree with the overall decision of &ldquo;
                {pass ? "Pass" : "Fail"}&rdquo;?
              </Typography>
            </Box>
            <RadioGroup
              sx={{ display: "inline-flex", flexDirection: "row" }}
              value={topLevelDisagree ? "disagree" : "agree"}
              onChange={(e) => {
                setTopLevelDisagree(
                  e.target.value === "disagree" ? true : false
                );
              }}
            >
              <FormControlLabel
                disabled={working}
                value="agree"
                control={<Radio />}
                label="Agree"
              />
              <FormControlLabel
                disabled={working}
                value="disagree"
                control={<Radio />}
                label="Disagree"
              />
            </RadioGroup>
          </Box>
          {findings.length > 0 && (
            <>
              <Stack>
                <Typography
                  mt={1}
                  color={theme.palette.grey[800]}
                  fontSize="115%"
                >
                  Are there any specific findings you disagree with or do not
                  understand?
                </Typography>
              </Stack>
              <Stack mb={1}>
                <FormGroup>
                  {findings.map((finding) => (
                    <FormControlLabel
                      key={finding.name}
                      value={finding.name}
                      disabled={working}
                      label={finding.display_name}
                      control={<Checkbox />}
                      checked={selectedRisks.includes(finding.name)}
                      onChange={(e: any) => {
                        // this should be a React.ChangeEvent<HTMLInputElement> but I can't get
                        // the MUI wrapper magic to work correctly
                        if (e.target.checked) {
                          setSelectedRisks((prevRisks) => {
                            if (prevRisks.includes(finding.name))
                              return prevRisks;
                            return [...prevRisks, finding.name];
                          });
                        } else {
                          setSelectedRisks((prevRisks) => {
                            if (!prevRisks.includes(finding.name))
                              return prevRisks;
                            return prevRisks.filter((r) => r !== finding.name);
                          });
                        }
                      }}
                    />
                  ))}
                </FormGroup>
              </Stack>
            </>
          )}
          <Stack>
            <Typography mt={1} color={theme.palette.grey[800]} fontSize="115%">
              Any additional details you&rsquo;d like us to know about?
            </Typography>
            <TextField
              multiline
              minRows={3}
              sx={{ marginTop: "0.5em" }}
              value={topLevelComments}
              onChange={(e) => setTopLevelComments(e.target.value)}
              disabled={working}
            />
          </Stack>
          <Box
            sx={{ display: "flex", gap: "0.5em", justifyContent: "flex-end" }}
            mt={2}
          >
            <Button onClick={onCancel} disabled={working}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={working}
              onClick={onSubmit}
            >
              Submit Feedback
            </LoadingButton>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}

export interface DecisionFeedbackRef {
  show: () => void;
  hide: () => void;
}

export interface Props {
  pass: boolean;
  trackingId: UUID;
  risks: APIRiskIndicators;
  onCancel?: () => void;
}

const DecisionFeedback = forwardRef(function DecisionFeedback(
  { pass, trackingId, risks, onCancel }: Props,
  ref: ForwardedRef<DecisionFeedbackRef>
) {
  const [widgetView, setWidgetView] = useState<"closed" | "form" | "success">(
    "closed"
  );

  useImperativeHandle(ref, () => ({
    show() {
      setWidgetView((currentView) => {
        if (currentView === "closed") return "form";
        return currentView;
      });
    },
    hide() {
      setWidgetView("closed");
    },
  }));

  return (
    <AnimatePresence>
      {widgetView === "form" && (
        <motion.div
          key="feedback-form"
          initial={{ height: 0, opacity: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
        >
          <FeedbackForm
            trackingId={trackingId}
            risks={risks}
            pass={pass}
            onCancel={() => {
              setWidgetView("closed");
              onCancel?.();
            }}
            onSuccess={() => setWidgetView("success")}
          />
        </motion.div>
      )}
      {widgetView === "success" && (
        <motion.div key="feedback-success">
          <Stack padding={3} textAlign="center">
            Thank you! Your feedback has been recorded.
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default DecisionFeedback;

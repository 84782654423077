import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ReplayIcon from "@mui/icons-material/Replay";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalTableState } from "./GlobalTableState";
import { useTrueBizApi } from "../../../api";

export interface Props {
  domains: string[];
  disabled?: boolean;
  onClick?: () => void;
  onSuccess?: () => void;
  onError?: (reason?: Error) => void;
}

export default function ExportButton({
  domains,
  disabled,
  onSuccess,
  onError,
}: Props) {
  const [_, setGlobalTableState] = useGlobalTableState(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [working, setWorking] = useState(false);
  const queryClient = useQueryClient();
  const api = useTrueBizApi();

  return (
    <LoadingButton
      variant="outlined"
      size="small"
      loading={working}
      startIcon={<ReplayIcon />}
      sx={{ pl: "2em", pr: "2em", display: "flex", flexShrink: 0 }}
      disabled={disabled || working}
      onClick={async () => {
        try {
          setWorking(true);
          setGlobalTableState((s) => ({ ...s, working: true, disabled: true }));

          await api.batchPauseResumeMonitoringDomain({
            domains,
            is_enabled: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["monitor portfolio", "monitors", "list"],
            refetchType: "all",
          });

          onSuccess?.();
        } catch (e) {
          const error =
            e instanceof Error ? e : e === undefined ? e : new Error(String(e));
          onError?.(error);
        } finally {
          setWorking(false);
          setGlobalTableState((s) => ({
            ...s,
            working: false,
            disabled: false,
          }));
        }
      }}
    >
      Resume
    </LoadingButton>
  );
}

import {
  Alert,
  IconButton,
  Drawer,
  Grid,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { useState, FormEvent } from "react";

import { HOSTNAME_INPUT_PATTERN } from "../../constants";
import { useTrueBizApi } from "../../api";
import APIStatusResponse from "../../types/APIStatusResponse";
import BooleanAttribute from "../BusinessAttributes/BooleanAttribute";
import UnauthorizedErrorDisplay from "../UnauthorizedErrorDisplay";
import UnsuccessfulErrorDisplay from "../UnsuccessfulErrorDisplay";
import NetworkFailureToast from "../NetworkFailureToast";

export default function WebsiteStatusCheck({
  open,
  onClose,
  onResetState,
}: {
  open: boolean;
  onClose: () => void;
  onResetState: () => void;
}) {
  const api = useTrueBizApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [websiteValue, setWebsiteValue] = useState<string>("");
  const [response, setResponse] = useState<APIStatusResponse | null>(null);
  const [lastRequestStatus, setLastRequestStatus] = useState<null | number>(
    null
  );

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setResponse(null);
      setLastRequestStatus(null);
      setLoading(true);

      setResponse(await api.getWebsiteStatus(websiteValue));
      setLastRequestStatus(200);
    } catch (e) {
      if (api.isResponseObjectError(e)) {
        setLastRequestStatus(e.status);
      }
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const onResetFailed = () => {
    setFailed(false);
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box minWidth={350} maxWidth={450} sx={{ p: 3 }}>
          <Typography
            variant="h6"
            fontWeight={600}
            component="h3"
            sx={{ mb: 0 }}
          >
            <IconButton onClick={onClose} color="primary">
              <ArrowBackIcon fontSize="inherit" sx={{ margin: 0 }} />
            </IconButton>{" "}
            Website Status Check
          </Typography>

          <Box paddingTop={3} width="100%">
            <form onSubmit={onSubmitForm} style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid xs={9} item>
                  <TextField
                    fullWidth
                    required
                    disabled={loading}
                    label="Website"
                    type="text"
                    size="small"
                    value={websiteValue}
                    onChange={(e) => setWebsiteValue(e.target.value)}
                    inputProps={{
                      title: "Enter a valid domain name (e.g. example.com)",
                      pattern: HOSTNAME_INPUT_PATTERN,
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={3}
                  alignItems="stretch"
                  justifyContent="stretch"
                  display="flex"
                >
                  <LoadingButton
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={loading}
                    loading={loading}
                    type="submit"
                  >
                    Check
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>

            <Box>
              <UnsuccessfulErrorDisplay lastRequestStatus={lastRequestStatus} />
              <UnauthorizedErrorDisplay
                lastRequestStatus={lastRequestStatus}
                onResetState={onResetState}
              />

              {response && (
                <Box paddingTop={4}>
                  <Box marginBottom={3}>
                    {response.alive ? (
                      <Alert
                        variant="outlined"
                        severity="success"
                        sx={{ fontWeight: 600 }}
                      >
                        The website appears to be alive
                      </Alert>
                    ) : (
                      <Alert
                        variant="outlined"
                        severity="warning"
                        sx={{ fontWeight: 600 }}
                      >
                        The website does not appear to alive
                      </Alert>
                    )}
                  </Box>

                  <Box marginBottom={2}>
                    <BooleanAttribute
                      label="Known social media link?"
                      value={response.social_media.is_social_media_link}
                      trueIsPositive={false}
                      failureText="Unknown"
                    />
                  </Box>

                  <BooleanAttribute
                    label="Known marketplace link?"
                    value={response.marketplace.is_marketplace_link}
                    trueIsPositive={false}
                    failureText="Unknown"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <NetworkFailureToast open={failed} onClose={onResetFailed} />
    </>
  );
}

import { Button } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import { useQuery } from "@tanstack/react-query";
import { useActiveMonitorsDrawer } from "../ActiveMonitorsDrawer";
import { useTrueBizApi } from "../../../api";

interface UnenrolledButtonProps extends ButtonProps {
  domain?: string | null | undefined;
}

function UnenrolledButton({ domain, ...etc }: UnenrolledButtonProps) {
  const drawer = useActiveMonitorsDrawer();

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => drawer.createMonitor({ domain: domain || undefined })}
      {...etc}
    >
      Enroll in monitoring
    </Button>
  );
}

interface EnrolledButtonProps extends ButtonProps {
  domain: string;
  loading?: boolean;
}

function EnrolledButton({
  domain,
  loading = false,
  disabled,
  ...etc
}: EnrolledButtonProps) {
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          window
            .open(
              `/monitoring/issues/open/?search=${domain}&monitorDomain=${domain}`,
              "_blank"
            )
            ?.focus();
        }}
        disabled={disabled}
        {...etc}
      >
        View Monitor
      </Button>
    </>
  );
}

export interface Props extends ButtonProps {
  domain: string;
}

export default function EnrollmentButton({ domain, disabled, ...etc }: Props) {
  const api = useTrueBizApi();

  const monitorQuery = useQuery({
    queryKey: ["getMonitors", domain],
    queryFn: () =>
      api.getMonitoringEnrollments({
        limit: 1,
        filterBy: [{ column: "domain", operator: "in", value: [domain] }],
      }),
  });

  if (monitorQuery.isLoading || monitorQuery.isError) {
    return <UnenrolledButton disabled {...etc} />;
  }

  if (monitorQuery.data?.enrollments.length) {
    return <EnrolledButton domain={domain} disabled={disabled} {...etc} />;
  }

  return <UnenrolledButton domain={domain} disabled={disabled} {...etc} />;
}

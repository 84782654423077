import { useRef, useState } from "react";
import { Alert, Box, Button, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";

import DecisionFeedback, { DecisionFeedbackRef } from "./DecisionFeedback";
import APIRiskIndicators from "../../../types/APIRiskIndicators";

export default function TrustRecommendation({
  pass,
  trackingId,
  risks,
}: {
  pass: boolean | null;
  trackingId: UUID | null | undefined;
  risks: APIRiskIndicators | null | undefined;
}) {
  const decisionFeedbackRef = useRef<DecisionFeedbackRef | null>(null);
  const [showFeedbackButton, setShowFeedbackButton] = useState(true);

  if (pass === null) {
    return null;
  }

  const IconClass = pass ? VerifiedIcon : ErrorIcon;

  return (
    <Box marginTop={0} data-testid="recommendation-container">
      <Alert
        icon={<IconClass />}
        severity={pass ? "success" : "error"}
        sx={{
          borderWidth: 1,
          borderColor: pass ? "success.light" : "error.light",
          borderStyle: "solid",
          ".MuiAlert-message": {
            width: "100%",
          },
          mb: 3,
        }}
      >
        {/* Using AlertTitle will cause centering issues. */}
        {/* You may also want to be aware that icon size=large
            and fontSize=large do not produce a consistent line height */}
        <Typography
          fontWeight={600}
          color={pass ? "success" : "error"}
          display="flex"
          justifyContent="space-between"
        >
          <span>{pass ? "Pass" : "Fail"}</span>
          {trackingId && risks && showFeedbackButton && (
            <Button
              size="small"
              variant="outlined"
              color={pass ? "success" : "error"}
              onClick={() => {
                if (!decisionFeedbackRef.current) return;
                decisionFeedbackRef.current.show();
                setShowFeedbackButton(false);
              }}
            >
              Leave Feedback
            </Button>
          )}
        </Typography>
      </Alert>
      {trackingId && risks && (
        <DecisionFeedback
          ref={decisionFeedbackRef}
          trackingId={trackingId}
          risks={risks}
          pass={pass}
          onCancel={() => {
            setShowFeedbackButton(true);
          }}
        />
      )}
    </Box>
  );
}

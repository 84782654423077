import { useCallback } from "react";
import {
  tableFilterByToServerFilterBy,
  tableOrderByToServerOrderBy,
  serverEnrollmentToTableMonitor,
  useGetIssuesByMonitorDomains,
  serverStatusToTableStatus,
  serverMonitorTypeToTableMonitorType,
} from "./utils";
import { useTrueBizApi } from "../../../api";
import MonitorsTable from "../MonitorsTable";
import { TableFilterBy, TableOrderBy } from "../MonitorsTable/types";

const useGetMonitors = () => {
  const api = useTrueBizApi();

  return useCallback(
    async ({
      limit,
      offset,
      fuzzySearch,
      filterBy,
      orderBy,
    }: {
      limit: number;
      offset: number;
      fuzzySearch: string;
      filterBy: TableFilterBy[];
      orderBy: TableOrderBy[];
    }) => {
      const monitors = await api.getMonitoringEnrollments({
        limit,
        offset,
        search: fuzzySearch,
        filterBy: [...tableFilterByToServerFilterBy(filterBy)],
        orderBy: tableOrderByToServerOrderBy(orderBy),
      });

      return {
        ...monitors,
        items: monitors.enrollments.map(serverEnrollmentToTableMonitor),
      };
    },
    [api]
  );
};

const useGetUniqueValuesForAlerts = () => {
  const api = useTrueBizApi();

  return useCallback(async () => {
    return api
      .getAnomaliesMetaUniqueValuesForAlerts({
        filterBy: [],
      })
      .then((alerts) => alerts.map((a) => a.type));
  }, [api]);
};

const useGetUniqueValuesForDomain = () => {
  const api = useTrueBizApi();

  return useCallback(async () => {
    return api.getAnomaliesMetaUniqueValuesForDomain({
      filterBy: [],
    });
  }, [api]);
};

const useGetUniqueValuesForMonitorTypes = () => {
  const api = useTrueBizApi();
  return useCallback(async () => {
    return api
      .getMonitoringEnrollmentsMetaUniqueValuesForMonitorTypes({
        filterBy: [],
      })
      .then((types) => types.map(serverMonitorTypeToTableMonitorType));
  }, [api]);
};

const useGetUniqueValuesForStatus = () => {
  const api = useTrueBizApi();

  return useCallback(async () => {
    return api
      .getMonitoringEnrollmentsMetaUniqueValuesForStatus({
        filterBy: [],
      })
      .then((statuses) => statuses.map(serverStatusToTableStatus));
  }, [api]);
};

const useGetUniqueValuesForAssignees = () => {
  const api = useTrueBizApi();

  return useCallback(
    () =>
      api
        .getAnomaliesMetaAssignableUsers()
        .then(
          (users) =>
            [
              "unassigned",
              ...users.map((user) => user.email).filter((x) => !!x),
            ] as string[]
        ),
    [api]
  );
};

export interface Props {
  onOpenMonitorDetail: (monitorId: UUID) => void;
  tableTitle?: React.ReactElement | null | undefined;
}

export default function MonitorPortfolio({
  onOpenMonitorDetail,
  tableTitle,
}: Props) {
  const api = useTrueBizApi();

  const getMonitors = useGetMonitors();
  const getIssuesByMonitorDomains = useGetIssuesByMonitorDomains();
  const getUniqueValuesForAssignees = useGetUniqueValuesForAssignees();
  const getUniqueValuesForAlerts = useGetUniqueValuesForAlerts();
  const getUniqueValuesForDomain = useGetUniqueValuesForDomain();
  const getUniqueValuesForMonitorTypes = useGetUniqueValuesForMonitorTypes();
  const getUniqueValuesForStatus = useGetUniqueValuesForStatus();

  return (
    <MonitorsTable
      tableTitle={tableTitle}
      key="monitor portfolio"
      description="monitor portfolio"
      columns={[
        "domain",
        "monitor_types",
        "status",
        "last_alerted",
        "next_run",
        "alerts",
      ]}
      selectable
      selectionActions={["export", "pause", "resume"]}
      getMonitors={getMonitors}
      getIssuesByMonitorDomains={getIssuesByMonitorDomains}
      getUniqueValuesForAssignees={getUniqueValuesForAssignees}
      getUniqueValuesForAlerts={getUniqueValuesForAlerts}
      getUniqueValuesForDomain={getUniqueValuesForDomain}
      getUniqueValuesForMonitorTypes={getUniqueValuesForMonitorTypes}
      getUniqueValuesForStatus={getUniqueValuesForStatus}
      onAssignMonitorIssuesToUser={async ({ monitorIds, userId }) => {
        await api.monitoringEnrollmentsMetaBulkAssignToUser(monitorIds, userId);
      }}
      onOpenMonitorDetail={onOpenMonitorDetail}
    />
  );
}

import { object, string, number, boolean, ValidationError } from "yup";

export const MonitoringCreateFormSchema = object({
  domain: string()
    .transform((value, original) => {
      try {
        const stripped = (value as string).trim();
        try {
          const url = new URL(stripped);
          return url.hostname;
        } catch {
          return stripped;
        }
      } catch {
        return value;
      }
    })
    .test(
      "domain",
      "Enter a valid domain name (e.g. example.com)",
      function (value) {
        if (!value) {
          return false;
        }
        const regexp = new RegExp(
          /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/
        );
        return regexp.test(value);
      }
    )
    .required(),
  profile_id: string().nullable(),
  external_ref_id: string().nullable(),
  closure_selected: boolean().required(),
  closure_frequency_days: number()
    .when(["closure_selected"], ([closure_selected], schema) =>
      schema.transform((closure_frequency_days) => {
        if (closure_selected) return closure_frequency_days;
        return undefined;
      })
    )
    .nullable(),
  content_selected: boolean().required(),
  content_frequency_days: number()
    .when(["content_selected"], ([content_selected], schema) =>
      schema.transform((content_frequency_days) => {
        if (content_selected) return content_frequency_days;
        return undefined;
      })
    )
    .nullable(),
  sentiment_selected: boolean().required(),
  sentiment_frequency_days: number()
    .when(["sentiment_selected"], ([sentiment_selected], schema) =>
      schema.transform((sentiment_frequency_days) => {
        if (sentiment_selected) return sentiment_frequency_days;
        return undefined;
      })
    )
    .nullable(),
  connected_entities_selected: boolean().required(),
  connected_entities_frequency_days: number()
    .when(
      ["connected_entities_selected"],
      ([connected_entities_selected], schema) =>
        schema.transform((connected_entities_frequency_days) => {
          if (connected_entities_selected)
            return connected_entities_frequency_days;
          return undefined;
        })
    )
    .nullable(),
}).test("monitor_selection", "Please select at least one", (obj) => {
  const nothingSelected =
    !obj.closure_selected &&
    !obj.content_selected &&
    !obj.sentiment_selected &&
    !obj.connected_entities_selected;
  if (nothingSelected) {
    return new ValidationError(
      "Please select at least one",
      undefined,
      "monitor_selection"
    );
  }
  return true;
});

import { useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import cn from "classnames";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import { format, parseISO } from "date-fns";

import styles from "./MonitorList.module.css";
import { APIMonitoringEnrollment } from "../../../../types/APIMonitoringEnrollment";

interface Props {
  monitor: APIMonitoringEnrollment;
  onEdit: (id: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
}

export default function MonitorRow({ monitor, onEdit, onDelete }: Props) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [working, setWorking] = useState(false);

  let nextRunLabel = monitor.next_run || "un-scheduled";
  if (monitor.next_run) {
    try {
      nextRunLabel = format(parseISO(monitor.next_run), "MMM d, yyyy");
    } catch (e) {
      // pass
    }
  }

  let previousAlertedLabel = monitor.last_alerted || "never";
  if (monitor.last_alerted) {
    try {
      previousAlertedLabel = format(
        parseISO(monitor.last_alerted),
        "MMM d, yyyy"
      );
    } catch (e) {
      // pass
    }
  }

  return (
    <div key={monitor.domain} className={cn(styles.monitor)}>
      {!showConfirmDelete && (
        <>
          <div className={styles.body}>
            <div className={styles.domain}>{monitor.domain}</div>
            <div className={cn(styles.meta)}>
              Next&nbsp;run:&nbsp;
              <span
                className={cn(styles.nextRun, {
                  [styles.never]: !monitor.next_run,
                })}
              >
                {nextRunLabel}
              </span>
              <span className={styles.divider}> / </span>
              Previous&nbsp;run:&nbsp;
              <span
                className={cn(styles.previousAlerted, {
                  [styles.never]: !monitor.last_alerted,
                })}
              >
                {previousAlertedLabel}
              </span>
            </div>
          </div>
          <div className={cn(styles.actions)}>
            <IconButton
              title="Edit monitor"
              onClick={() => {
                onEdit?.(monitor.domain);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              title="Delete monitor"
              onClick={() => {
                setShowConfirmDelete(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </>
      )}
      {showConfirmDelete && (
        <div className={styles.confirmDelete}>
          <Typography className={styles.message} color="error">
            Unenroll <strong>{monitor.domain}</strong>?
          </Typography>
          <div className={styles.actions}>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmDelete(false)}
              disabled={working}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={async () => {
                try {
                  setWorking(true);
                  await onDelete?.(monitor.domain);
                  setWorking(false);
                  setShowConfirmDelete(false);
                } finally {
                  setWorking(false);
                }
              }}
              loading={working}
            >
              Unenroll
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
}

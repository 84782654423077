import { useEffect, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";
import { useQueryClient } from "@tanstack/react-query";

import CloseIcon from "@mui/icons-material/Close";

import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import MonitoringIcon from "./monitoring-icon.svg";
import SuccessIcon from "./success-icon.svg";
import FailureIcon from "./failure-icon.svg";

import { useTrueBizApi } from "../../../../api";
import { useShowNetworkFailureToast } from "../../../NetworkFailureToast";

const cleanCsvFileName = (filename: string) => {
  let cleaned = filename;
  cleaned = filename.replace(/\.csv$/i, "");
  cleaned = cleaned.replace(/__errors$/i, "");
  cleaned = cleaned.replace(/__success$/i, "");

  return cleaned;
};

export interface Props {
  onCancel: () => void;
}

export default function MonitorCSVEnroll({ onCancel }: Props) {
  const theme = useTheme();
  const api = useTrueBizApi();
  const showToast = useShowNetworkFailureToast();
  const queryClient = useQueryClient();

  const [working, setWorking] = useState(false);
  const [inputFileName, setInputFileName] = useState("");
  const [successFileContents, setSuccessFileContents] = useState<Blob | null>(
    null
  );
  const [errorFileContents, setErrorFileContents] = useState<Blob | null>(null);

  const [emptyTemplateWorking, setEmptyTemplateWorking] = useState(false);
  const [filledTemplateWorking, setFilledTemplateWorking] = useState(false);

  const onUploadCsv = useCallback(
    async (file: File) => {
      try {
        setWorking(true);

        setSuccessFileContents(null);
        setErrorFileContents(null);
        setInputFileName("");

        setInputFileName(file.name);

        const csvFileContents = new Blob([await file.arrayBuffer()], {
          type: file.type,
        });

        const successFileContents =
          await api.monitoringEnrollmentsMetaBulkEnroll(csvFileContents);

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["getMonitor"],
            refetchType: "active",
            exact: false,
          }),
          queryClient.invalidateQueries({
            queryKey: ["getMonitors"],
            refetchType: "active",
            exact: false,
          }),
        ]);

        setSuccessFileContents(successFileContents);
      } catch (e) {
        if (api.isResponseObjectError(e)) {
          const errorFileContents = await e.blob();
          setErrorFileContents(errorFileContents);
        } else {
          showToast();
        }
      } finally {
        setWorking(false);
      }
    },
    [api, showToast, queryClient]
  );

  const onDownloadEmptyTemplate = async () => {
    try {
      setEmptyTemplateWorking(true);

      const template =
        await api.monitoringEnrollmentsMetaBulkEnrollTemplatesEmpty();
      saveAs(template, "truebiz-bulk-enrollments-empty-template.csv");
    } catch (e) {
      showToast();
    } finally {
      setEmptyTemplateWorking(false);
    }
  };

  const onDownloadFilledTemplate = async () => {
    try {
      setFilledTemplateWorking(true);

      const filledTemplate =
        await api.monitoringEnrollmentsMetaBulkEnrollTemplatesFilled();
      saveAs(filledTemplate, "truebiz-bulk-enrollments-filled-template.csv");
    } catch (e) {
      showToast();
    } finally {
      setFilledTemplateWorking(false);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: working,
    maxFiles: 1,
    accept: {
      // this is the official mime type
      "text/csv": [".csv", ".tsv"],

      // but it's the wild west out there
      "text/plain": [".csv", ".tsv"],
      "text/x-csv": [".csv", ".tsv"],
      "application/csv": [".csv", ".tsv"],
      "text/comma-separated-values": [".csv", ".tsv"],
      "text/x-comma-separated-values": [".csv", ".tsv"],
      "text/tab-separated-values": [".csv", ".tsv"],

      // 🫡 for those heroes who have edited their files in excel
      "application/vnd.ms-excel": [".csv", ".tsv"],
    },
  });

  useEffect(() => {
    if (!acceptedFiles?.length) return;
    const [csvFile] = acceptedFiles;
    onUploadCsv(csvFile);
  }, [acceptedFiles, onUploadCsv]);

  if (successFileContents) {
    return (
      <Stack
        style={{
          width: "50vw",
          maxWidth: "425px",
          paddingLeft: "0.75em",
          paddingRight: "0.75em",
          paddingBottom: "0.75em",
          display: "flex",
          flex: 1,
        }}
      >
        <h2>
          <IconButton
            onClick={() => {
              onCancel();
            }}
            disabled={working}
          >
            <CloseIcon />
          </IconButton>
          CSV Upload
        </h2>
        <Stack
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "6em",
          }}
        >
          <img src={SuccessIcon} width={175} alt="" />
          <h3 style={{ color: "#66996C" }}>Successfully Processed</h3>
          <Button
            onClick={() => {
              saveAs(
                successFileContents,
                `${cleanCsvFileName(inputFileName)}__success.csv`
              );
            }}
            color="success"
          >
            Download Result Summary
          </Button>
        </Stack>
      </Stack>
    );
  }

  if (errorFileContents) {
    return (
      <Stack
        style={{
          width: "50vw",
          maxWidth: "425px",
          paddingLeft: "0.75em",
          paddingRight: "0.75em",
          paddingBottom: "0.75em",
          display: "flex",
          flex: 1,
        }}
      >
        <h2>
          <IconButton
            onClick={() => {
              onCancel();
            }}
            disabled={working || emptyTemplateWorking || filledTemplateWorking}
          >
            <CloseIcon />
          </IconButton>
          CSV Upload
        </h2>
        <Stack
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "6em",
          }}
        >
          <img src={FailureIcon} width={175} alt="" />
          <h3 style={{ textAlign: "center", color: "#CC3333" }}>
            Processing Error
          </h3>
          <Typography
            color="error"
            sx={{ mb: 3, maxWidth: "75%", textAlign: "center" }}
          >
            Please download the error summary, correct the errors, and re-upload
            your file.
          </Typography>
          <Button
            onClick={() => {
              saveAs(
                errorFileContents,
                `${cleanCsvFileName(inputFileName)}__errors.csv`
              );
            }}
            color="error"
          >
            Download Error Summary
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      style={{
        width: "50vw",
        maxWidth: "425px",
        paddingLeft: "0.75em",
        paddingRight: "0.75em",
        paddingBottom: "0.75em",
        display: "flex",
        flex: 1,
      }}
    >
      <h2>
        <IconButton
          onClick={() => {
            onCancel();
          }}
          disabled={working}
        >
          <CloseIcon />
        </IconButton>
        CSV Upload
      </h2>
      <Stack
        sx={{
          alignItems: "center",
          mb: "2em",
        }}
      >
        <img src={MonitoringIcon} width={100} alt="" />
        <Typography textAlign="center" sx={{ mb: 2 }}>
          You can enroll or update multiple <br />
          merchants by uploading a CSV.
          <br />
        </Typography>
        <Stack
          {...getRootProps()}
          sx={{
            textAlign: "center",
            mt: 3,
            border: `2px dashed ${theme.palette.grey[400]}`,
            background: theme.palette.grey[100],
            width: "100%",
            borderRadius: "5px",
          }}
        >
          <input {...getInputProps()} />
          <Stack
            sx={{
              padding: 4,
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: working
                  ? theme.palette.grey[300]
                  : theme.palette.grey[600],
              }}
            >
              <strong>
                Drag and drop your <br /> CSV file here
              </strong>
            </Typography>
            <LoadingButton sx={{ mt: 3 }} loading={working}>
              Select a CSV file
            </LoadingButton>
          </Stack>
        </Stack>
        <Stack sx={{ mt: 3 }}>
          <Typography>
            <LoadingButton
              loading={emptyTemplateWorking}
              disabled={working}
              onClick={onDownloadEmptyTemplate}
            >
              Download an empty template
            </LoadingButton>
          </Typography>
          <Typography>
            <LoadingButton
              loading={filledTemplateWorking}
              disabled={working}
              onClick={onDownloadFilledTemplate}
            >
              Download a pre-filled template
            </LoadingButton>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
